import * as Sentry from "@sentry/nuxt";

Sentry.init({
    dsn: useRuntimeConfig().public.sentry.dsn,

    tracesSampleRate: 1.0,

    enabled: useRuntimeConfig().public.nodeEnv !== "development",

    environment: useRuntimeConfig().public.nodeEnv ?? "production",
});
